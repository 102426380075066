import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sellos',
  templateUrl: './sellos.component.html',
  styleUrls: ['./sellos.component.scss']
})
export class SellosComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('  Sellos | Vile ');
    this.meta.updateTag({ name: 'keywords', content: 'sellos de hule guatemala, sellos automáticos, sellos madera guatemala, fechadores guatemala, Sellos de escritorio, sellos trodat, sellos shiny, sellos de bolsillo, multisellos vile' });
    this.meta.updateTag({ name: 'description', content: 'Nos dedicamos a la fabricación de sellos de hule, de madera, automáticos y fechadores en Guatemala. Personalizamos sellos y elaboramos sellos urgentes.' });
    this.meta.updateTag({ property: 'og:description', content: 'Nos dedicamos a la fabricación de sellos de hule, de madera, automáticos y fechadores en Guatemala. Personalizamos sellos y elaboramos sellos urgentes.' });
  }

}
