<div class="footer">
    <div class="tarjetas">
        <div class="tarjeta">
            <img routerLink="/inicio" src="assets/img/Logo-blanco.png" alt="...">
        </div>
        <div class="tarjeta">
            <h3>
                Mapa de Sitio
            </h3>
            <p routerLink="/inicio">Inicio</p>
            <p routerLink="/acerca-de">Acerca de</p>
            <p routerLink="/sellos">Multisellos</p>
            <p routerLink="/litografia">Litografía</p>
            <p routerLink="/contactenos">Contacto</p>
            <p routerLink="/sellos-personalizados">Sellos Personalizados</p>
            <!-- <p routerLink="">Línea ecológica</p> -->
        </div>
        <div class="tarjeta">
            <h3>Encuéntranos</h3>
            <a href="https://goo.gl/maps/Lo3ZS7dZeUHNiZuw9" target="_blank">6a ave. 5-31 zona 2, Guatemala, CA</a>
            <a href="tel:23142222" target="_blank">Tel: 2314-2222</a>
            <a href="https://wa.me/50257556140?text=¡Buenas%20tardes!" target="_blank">Whatsapp: 5755-6140</a>
            <a href="mailto:ventas1@vile.com.gt" target="_blank">Email: ventas1@vile.com.gt</a>
            <a href="mailto:ventas2@vile.com.gt" target="_blank">ventas2@vile.com.gt</a>
        </div>
        <div class="tarjeta">
            <h3>
                Síguenos
            </h3>
            <div class="row">
                <a href="https://www.facebook.com/Sellos-Multisellos-Vile-243698232664512/" target="_blank"><img src="assets/img/IconoFB.png" alt="..."></a>
                <a href="https://www.instagram.com/multisellosvilegt/" target="_blank"><img src="assets/img/IconoInsta.png" alt="..."></a>
                <!-- <a href="https://www.instagram.com/litografiavilegt/" target="_blank"><img src="../../../assets/svg/002-instagram.svg" alt=""></a> -->
            </div>
        </div>
    </div>
    <div class="copy">
        <hr>
        <p>Copyright Ⓒ VILE 2022, Todos los derechos reservados.</p>
        <p> Diseño y desarrollo web por <a href="https://www.wearehumanbrands.com/" target="_blank">Human Brands</a></p>
    </div>
</div>